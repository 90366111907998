module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Goldstar Securities, Inc.","short_name":"Goldstar","start_url":"/","background_color":"#ffffff","theme_color":"#6f6f6f","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a91b855672761e9d11818fbff57758be"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://goldstarsecurities.pages.dev"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
